import dynamic from 'next/dynamic'
import styleConfig from '@/styles/style-config'

const ContentImageBlock = dynamic(() => import('@/components/content/content-image-block'))
const ContentVideoBlock = dynamic(() => import('@/components/content/content-video-block'))
const ContentTextBlock = dynamic(() => import('@/components/content/content-text-block'))

const Content5050 = (props) => {
  const desktopLayout = props?.desktopLayout || 'Left first'
  const contentBlocks = props?.contentBlocksCollection?.items

  return (
    <>
      {contentBlocks?.length == 2 && (
        <div className="content-5050">
          <div className="grid lg:grid-cols-2">
            <div
              className={`content-5050__col flex w-full h-full ${
                desktopLayout == 'Left first' ? 'lg:order-1' : 'lg:order-2'
              }`}
            >
              {contentBlocks?.[0]?.__typename == 'ImageBlock' && (
                <ContentImageBlock {...contentBlocks?.[0]} />
              )}
              {contentBlocks?.[0]?.__typename == 'VideoBlock' && (
                <ContentVideoBlock {...contentBlocks?.[0]} />
              )}
              {contentBlocks?.[0]?.__typename == 'TextBlock' && (
                <ContentTextBlock {...contentBlocks?.[0]} />
              )}
            </div>
            <div
              className={`content-5050__col flex w-full h-full ${
                desktopLayout == 'Left first' ? 'lg:order-2' : 'lg:order-1'
              }`}
            >
              {contentBlocks?.[1]?.__typename == 'ImageBlock' && (
                <ContentImageBlock {...contentBlocks?.[1]} />
              )}
              {contentBlocks?.[1]?.__typename == 'VideoBlock' && (
                <ContentVideoBlock {...contentBlocks?.[1]} />
              )}
              {contentBlocks?.[1]?.__typename == 'TextBlock' && (
                <ContentTextBlock {...contentBlocks?.[1]} />
              )}
            </div>
          </div>
        </div>
      )}
      <style jsx>
        {`
          .content-5050__col {
            min-height: 500px;
          }

          @media (min-width: ${styleConfig.theme.screens.md}) {
            .content-5050__col {
              min-height: 400px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.lg}) {
            .content-5050__col {
              min-height: 500px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .content-5050__col {
              min-height: 600px;
            }
          }
        `}
      </style>
    </>
  )
}

export default Content5050
